<template>
    <app-timeline>
      <app-timeline-item
        v-for="(item, index) in reviewsData"
        :id="index"
        :key="index"
        :title="'Interviewer : '+item.review_by"
        :subtitle="'Feedback : '+item.msg"
        icon="UserIcon"
        :time="item.time"
        :item="item"
        variant="info"
      />
    </app-timeline>
</template>

<script>
import {
  BCard,
} from 'bootstrap-vue'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'

export default {
  components: {
    BCard,
    AppTimeline,
    AppTimelineItem,
  },
  props: {
    reviewsData: {
      type: Array,
      required: true,
    },
  },
}
</script>

<style>

</style>
