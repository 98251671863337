<template>
  <div>
    <b-row>
      <b-col
        md="4"
        v-for="(data, index) in dataViewItems"
        :key="index"
        v-if="data != 'undefined'"
      >
        <b-form-group
          :label="index | formatLabel"
          label-size="sm"
          label-class="text-muted text-capitalize"
        >
          <div class="text-capitalize">
            {{ data ? data : "No Data" }}
          </div>
        </b-form-group>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BForm,
  BFormCheckbox,
} from "bootstrap-vue";

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BFormCheckbox,
    BForm,
  },
  name: "DataView",
  props: {
    dataItems: {
      required: true,
    },
    showItems: {
      type: Array,
      required: true,
    },
  },
  created() {
    const self = this;
    if (self.showItems.length > 0) {
      self.dataViewItems = Object.fromEntries(
        Object.entries(self.dataItems).filter(
          ([key]) => self.showItems.indexOf(key) > -1
        )
      );
    }
  },
  data() {
    return {
      dataViewItems: {},
    };
  },
};
</script>
