<template>
  <b-card title="Candidate Status/Timeline">
    <app-timeline>
      <app-timeline-item
        v-for="(item, index) in historyData"
        :id="index"
        :key="index"
        :title="item.client_side_ui.title"
        :subtitle="item.msg"
        :icon="item.client_side_ui.icon"
        :time="item.time"
        :item="item"
        :variant="item.client_side_ui.variant"
      />
    </app-timeline>
  </b-card>
</template>

<script>
import {
  BCard,
} from 'bootstrap-vue'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'

export default {
  components: {
    BCard,
    AppTimeline,
    AppTimelineItem,
  },
  props: {
    historyData: {
      type: Array,
      required: true,
    },
  },
}
</script>

<style>

</style>
