var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-alert',{attrs:{"variant":"danger","show":_vm.userCandidateData === undefined}},[_c('h4',{staticClass:"alert-heading"},[_vm._v("Error fetching user data")]),_c('div',{staticClass:"alert-body"},[_vm._v(" No user found with this user id. Check "),_c('b-link',{staticClass:"alert-link",attrs:{"to":{ name: 'candidates' }}},[_vm._v(" referral List ")])],1)]),(_vm.userCandidateData)?[_c('interview-add',{attrs:{"is-add-new-interview-sidebar-active":_vm.isAddNewInterviewSidebarActive,"candidate":_vm.candidate,"candidate-change-flag":_vm.candidateChangeFlag},on:{"update:isAddNewInterviewSidebarActive":function($event){_vm.isAddNewInterviewSidebarActive=$event},"update:is-add-new-interview-sidebar-active":function($event){_vm.isAddNewInterviewSidebarActive=$event},"update:candidate":function($event){_vm.candidate=$event},"update:candidateChangeFlag":function($event){_vm.candidateChangeFlag=$event},"update:candidate-change-flag":function($event){_vm.candidateChangeFlag=$event},"refetch-data":_vm.refetchCandidatesData}}),_c('b-row',[_c('b-col',{attrs:{"cols":"12","xl":"12","lg":"12","md":"12"}},[_c('candidate-view-info',{attrs:{"user-data":_vm.userCandidateData,"custom-fields":_vm.customFields}})],1)],1),_c('b-card',[_c('h4',[_vm._v("Feedbacks")]),(
          _vm.userCandidateData.interviews.length == 0 ||
          _vm.userCandidateData.hasReviews == 0
        )?_c('div',[_c('h6',{staticClass:"mt-2 text-secondary"},[_vm._v(" No Reviews / Feedbacks yet or You can't see it. "),_c('feather-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('No Reviews'),expression:"'No Reviews'",modifiers:{"hover":true,"top":true}}],staticClass:"btn-link cursor-pointer",attrs:{"icon":"FrownIcon"}})],1)]):_vm._e(),_vm._l((_vm.userCandidateData.interviews),function(interview,i){return _c('div',{key:i},[_c('candidate-interview-view-timeline',{attrs:{"reviews-data":interview.reviews}})],1)})],2),_c('b-card',[_c('b-tabs',[_c('b-tab',{attrs:{"active":"","title":"Candidate Status / Timeline"}},[_c('candidate-view-timeline',{attrs:{"history-data":_vm.userCandidateData.histories}})],1),_c('b-tab',{attrs:{"title":"Assigned Interviews"}},[_c('b-card-text',[_c('div',[_c('div',{staticClass:"custom-search d-flex justify-content-end"},[_c('b-form-group',[_c('div',{staticClass:"d-flex align-items-center"},[_c('b-button',{staticClass:"btn add-btn mr-2",on:{"click":_vm.assignInterview}},[_vm._v("Add Interview")]),_c('label',{staticClass:"mr-1"},[_vm._v("Search")]),_c('b-form-input',{staticClass:"d-inline-block",attrs:{"placeholder":"Search","type":"text"},model:{value:(_vm.searchTerm),callback:function ($$v) {_vm.searchTerm=$$v},expression:"searchTerm"}})],1)])],1),_c('vue-good-table',{attrs:{"columns":_vm.columns,"rows":_vm.userCandidateData.interviews,"rtl":_vm.direction,"search-options":{
                  enabled: true,
                  externalQuery: _vm.searchTerm,
                },"pagination-options":{
                  enabled: true,
                  perPage: _vm.pageLength,
                }},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field === 'action')?_c('span',[_c('span',[_c('b-dropdown',{attrs:{"variant":"link","toggle-class":"text-decoration-none","no-caret":""},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('feather-icon',{staticClass:"text-body align-middle mr-25",attrs:{"icon":"MoreVerticalIcon","size":"16"}})]},proxy:true}],null,true)},[_c('b-dropdown-item',{on:{"click":function($event){return _vm.viewInterview(props.row.hashid)}}},[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"EyeIcon"}}),_c('span',[_vm._v("View")])],1)],1)],1)]):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}},{key:"pagination-bottom",fn:function(props){return [_c('div',{staticClass:"d-flex justify-content-between flex-wrap"},[_c('div',{staticClass:"d-flex align-items-center mb-0 mt-1"},[_c('span',{staticClass:"text-nowrap"},[_vm._v(" Showing 1 to ")]),_c('b-form-select',{staticClass:"mx-1",attrs:{"options":['3', '5', '10']},on:{"input":function (value) { return props.perPageChanged({ currentPerPage: value }); }},model:{value:(_vm.pageLength),callback:function ($$v) {_vm.pageLength=$$v},expression:"pageLength"}}),_c('span',{staticClass:"text-nowrap"},[_vm._v(" of "+_vm._s(props.total)+" entries ")])],1),_c('div',[_c('b-pagination',{staticClass:"mt-1 mb-0",attrs:{"value":1,"total-rows":props.total,"per-page":_vm.pageLength,"first-number":"","last-number":"","align":"right","prev-class":"prev-item","next-class":"next-item"},on:{"input":function (value) { return props.pageChanged({ currentPage: value }); }},scopedSlots:_vm._u([{key:"prev-text",fn:function(){return [_c('feather-icon',{attrs:{"icon":"ChevronLeftIcon","size":"18"}})]},proxy:true},{key:"next-text",fn:function(){return [_c('feather-icon',{attrs:{"icon":"ChevronRightIcon","size":"18"}})]},proxy:true}],null,true)})],1)])]}}],null,false,3048522887)})],1)])],1)],1)],1)]:_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }