<template>
  <b-card title="Candidate Details :">
    <b-row class="">
      <b-col cols="12">
        <b-link @click="$router.go(-1)" class="mr-2 min-w-200">
          <feather-icon icon="ChevronLeftIcon" /> Back
        </b-link>
        <b-button
          pill
          variant="primary"
          :to="{
            name: 'add-employee',
            query: {
              name: userData.name,
              position: userData.position,
              dob: userData.date_of_birth,
              number: userData.mobile_number,
              id: $route.params.id,
            },
          }"
          class="mr-2 min-w-200"
        >
          <feather-icon icon="UserPlusIcon" class="mr-1" />
          <span class="text-nowrap">Make Employee</span>
        </b-button>
        <b-button
          pill
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          v-if="userData.resume_file_path"
          variant="outline-primary"
          class="mr-2 min-w-200"
          @click.stop.prevent="openWindow(userData.resume_file_path)"
        >
          <feather-icon icon="EyeIcon" class="mr-25" />
          <span>View Resume</span>
        </b-button>
      </b-col>
    </b-row>
    <br />
    <DataView :dataItems="userData" :showItems="showItems" />

    <b-row>
      <b-col md="4">
        <b-form-group
          label="Date Of Birth"
          label-size="sm"
          label-class="text-muted text-capitalize"
        >
          <div class="text-capitalize">
            {{ userData.date_of_birth | formatDate }}
          </div>
        </b-form-group>
      </b-col>

      <b-col md="4">
        <b-form-group
          label="Position"
          label-size="sm"
          label-class="text-muted text-capitalize"
        >
          <div class="text-capitalize">
            {{ userData.designation }}
          </div>
        </b-form-group>
      </b-col>

      <b-col md="4" v-if="userData.recommendation">
        <b-form-group
          label="Recommendation"
          label-size="sm"
          label-class="text-muted text-capitalize"
        >
          <div class="text-capitalize">
            {{ userData.recommendation }}
          </div>
        </b-form-group>
      </b-col>

      <b-col md="4" v-if="userData.status == 2">
        <b-form-group
          label="Referral Reject Reason"
          label-size="sm"
          label-class="text-muted text-capitalize"
        >
          <div class="text-capitalize">
            {{ userData.referral_reject_reason }}
          </div>
        </b-form-group>
      </b-col>

      <b-col md="4">
        <b-form-group
          label="Year Of Experience"
          label-size="sm"
          label-class="text-muted text-capitalize"
        >
          <div class="text-capitalize">
            {{ getYearsCount(userData.years_of_experience) }}
            Years
            {{ getMonthsCount(userData.years_of_experience) }}
            Months
          </div>
        </b-form-group>
      </b-col>
      <b-col md="4">
        <b-form-group
          label="Years of relevant experience"
          label-size="sm"
          label-class="text-muted text-capitalize"
        >
          <div class="text-capitalize">
            {{ getYearsCount(userData.years_of_relevant_experience) }}
            Years
            {{ getMonthsCount(userData.years_of_relevant_experience) }}
            Months
          </div>
        </b-form-group>
      </b-col>

      <b-col md="4">
        <b-form-group
          label="CTC"
          label-size="sm"
          label-class="text-muted text-capitalize"
        >
          <div class="text-capitalize">
            {{
              userData.is_ctc_private
                ? userDataLocal.uid == userData.creator_uid
                  ? userData.ctc
                  : "******"
                : userData.ctc == null
                ? "Not Mentioned"
                : userData.ctc
            }}
            <feather-icon
              v-if="userData.is_ctc_private"
              v-b-tooltip.hover.top="
                'Only admin and the person who added candidate can see'
              "
              icon="EyeOffIcon"
            />
          </div>
        </b-form-group>
      </b-col>
      <b-col md="4" v-if="userData.expected_ctc">
        <b-form-group
          label="Expected CTC"
          label-size="sm"
          label-class="text-muted text-capitalize"
        >
          <div class="text-capitalize">
            {{
              userData.is_ectc_private
                ? userDataLocal.uid == userData.creator_uid
                  ? userData.expected_ctc
                  : "******"
                : userData.ctc == null
                ? "Not Mentioned"
                : userData.expected_ctc
            }}
            <feather-icon
              v-if="userData.is_ectc_private"
              v-b-tooltip.hover.top="
                'Only admin and the person who added candidate can see'
              "
              icon="EyeOffIcon"
            />
          </div>
        </b-form-group>
      </b-col>
    </b-row>

    <b-row>
      <b-col v-for="(data, index) in customFields" v-bind:key="index" md="6">
        <b-row v-if="userData.get_customfields_data['field_' + data.id]">
          <b-col md="4" class="pb-50 font-weight-bold">
            {{ data.label }}
          </b-col>
          <b-col md="7" class="pb-50">
            {{ userData.get_customfields_data["field_" + data.id] }}
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import { BCard, BRow, BCol, BButton, VBTooltip } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import Vue from "vue";
import VueCookies from "vue-cookies";
Vue.use(VueCookies);
import DataView from "@/components/DataView.vue";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    DataView,
  },
  directives: {
    Ripple,
    "b-tooltip": VBTooltip,
  },
  props: {
    userData: {
      type: Object,
      required: true,
    },
    customFields: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      userDataLocal: this.$cookies.get("userData"),
      showItems: [
        "email",
        "name",
        "mobile_number",
        "current_company",
        "notice_period",
        "previous_company",
        "skills",
        "remark",
        "status_label",
        "referral_status_label",
        "source",
        "current_address",
        "gender",
        "marital_status",
        "determine",
      ],
    };
  },
  methods: {
    openWindow(link) {
      window.open(link);
    },
    updateStatus() {
      this.$store
        .dispatch("app-candidates/updateCandidateStatus", {
          id: this.$route.params.id,
        })
        .then((res) => {
          console.log(res);
        });
    },
    getYearsCount(year) {
      return parseInt(year / 12);
    },
    getMonthsCount(year) {
      return parseInt(year % 12);
    },
  },
};
</script>

<style>
</style>
